<template>
	<div class="">
		<div class="tableConTop">
			<el-row>
				<el-col :span="6" class="tableConTopLeft">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>
							<span class="breadcrumbLink" @click="backPageAction()">
								<i class="el-icon-back iconBack"></i>{{$t('ca5a01a5adf20fe7')}}</span>
						</el-breadcrumb-item>
						<el-breadcrumb-item>{{ pageTitle }}<span>(条码)</span></el-breadcrumb-item>
					</el-breadcrumb>

				</el-col>
				<el-col :span="18" class="tableConTopRig">

				</el-col>
			</el-row>
		</div>
		
		<div class="">
			<!-- <el-card> -->
				<div class="filterCon" style="">
					<ul class="filterConList" style="text-align: left;">
						<li>
							<span style="display:inline-block;width: 50px;">{{$t('i18nn_54672b9140a3afe0')}}</span>
							<el-select v-model="printerVal" :placeholder="$t('hytxs0000100')" filterable clearable
								style="margin-left: 10px;" @change="changePrinter">
								<el-option v-for="(item, index) in printerList" :key="index" :label="item.name"
									:value="item.value"></el-option>
							</el-select>
						</li>
						<li>
							<span style="display:inline-block;width: 50px;">{{$t('i18nn_a85af4ba66084d28')}}</span>
							<el-select v-model="option.size" :placeholder="$t('hytxs0000100')" filterable clearable
								style="margin-left: 10px;">
								<el-option v-for="(item, index) in selectOption.sizeList" :key="index" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</li>
						
					</ul>
				</div>
			<!-- </el-card> -->
			<el-form ref="form" :size="''" :rules="formRules" :model="form" label-width="120px" style="width: 100%"
				v-loading="loading" :element-loading-text="$t('44bd089554515c4d')">

				<el-card id="formCard">
					<div>
						<!-- <el-form-item :label="'Customer #'" prop="customer">
							<el-input type="text" clearable v-model="form.customer" style="width:300px;"></el-input>
						</el-form-item> -->

						<el-form-item :label="'Container #'" prop="container">
							<el-input type="text" clearable v-model="form.container" style="width:300px;" @input="valToUpperCase"></el-input>
							<span style="color:#67c23a;">自动转换为大写</span>
						</el-form-item>
						
						<el-form-item :label="'Direction #'" prop="container">
							<el-input type="text" clearable v-model="form.direction" style="width:300px;" @input="valToUpperCase2"></el-input>
							<span style="color:#67c23a;">自动转换为大写</span>
						</el-form-item>
						
						<el-form-item :label="'Location #'" prop="">
							<el-input type="text" clearable v-model="form.location" style="width:300px;" @input="valToUpperCase3"></el-input>
							<span style="color:#67c23a;">自动转换为大写</span>
						</el-form-item>
						
						<el-form-item :label="'Customer #'" prop="customer">
							<el-input type="text" clearable v-model="form.customer" style="width:300px;"></el-input>
							<span  class="mark-customer" v-show="false">
								<imgFont :text="form.customer" :options="{
									width: 300,
									height: 22,
									fontSize: 18,
								}"></imgFont>
													
							</span>
						</el-form-item>
						
						<el-form-item :label="'Pallet #'" prop="pallet">
							<el-input-number v-model="form.pallet" controls-position="right"></el-input-number>
						</el-form-item>

						<!-- <el-form-item :label="'Date #'" prop="date">
							<el-date-picker v-model="form.date" type="date" :placeholder="$t('68efabd029c860a9')" format="MM/dd/yyyy"
								value-format="MM/dd/yyyy">
							</el-date-picker>
						</el-form-item> -->
						<el-form-item :label="''">
							<barcode 
								:code="(form.container?form.container:'')+'-'+(form.direction?form.direction:'')" 
								:options="{height:80,width:2,fontSize:40}"></barcode>
						</el-form-item>


					</div>
				</el-card>

			</el-form>
			<div style="margin: 10px 0;">
				<!-- <el-button type="primary" style="width: 220px;" @click="submitForm('form', '3')" v-if="!!form.id">{{ $t('i18nn_d552aa9f1c14363f') }}</el-button> -->
				<!-- <el-button type="primary" icon="el-icon-view"  style="width: 220px;" @click="submitForm('form', '2')">{{$t('i18nn_17a941b7c4c108fe')}}</el-button> -->
				<!-- <el-button type="warning" icon="el-icon-download"  style="width: 220px;" @click="submitForm('form', '1')">{{$t('i18nn_245883c80f181c4a')}}</el-button> -->
				<el-button type="primary" icon="el-icon-printer" @click="printAction()">直接打印</el-button>
				
				<el-button type="primary" icon="el-icon-view"  style="width: 220px;" @click="openPreview()">预览或下载</el-button>
				
				
				
			
			</div>
		</div>
		<!-- </el-dialog> -->
		<!-- 托盘标打印预览 -->
		<dialogPalletMarkImg2 :openTime="palletMarkImgOpenTime" :row="palletMarkImgRow"></dialogPalletMarkImg2>
	</div>
</template>
<script>
	import {
		exportPdfByPalletMark2
	} from '@/utils/PdfPalletMark.js'
	
	import {
		priterPdfView,
		GetPrinterList,
	} from '@/utils/LodopPrinter.js';
	
	import imgFont from '@/components/Common/imgFont.vue';
	
	import barcode from '@/components/Common/barcode.vue';
	
	import dialogPalletMarkImg2 from '@/components/WarehouseCenter2/Other/dialogPalletMarkImg2.vue';
	
	
	// import {
	// 	downloadPdfBase64
	// } from '@/utils/pdfUtils.js';
	// import {
	// 	priterPdfView,
	// 	GetPrinterList,
	// } from '@/utils/LodopPrinter.js';
	export default {
		components: {
			imgFont,
			barcode,
			dialogPalletMarkImg2
		},
		data() {
			return {
				pageTitle: this.$t('i18nn_ea519c59cd7bec7d'),
				// printerVal: "",
				// printerList: [],
				printerVal: "",
				printerList: [],
				
				list: [],
				
				selectOption: {
					sizeList: [
					{
						label: "101.6*152.4(4*6in)",
						value: "1"
					},{
						label: "210*297(A4)",
						value: "2"
					}],
				},
				
				option: {
					size: '1',
				},
				
				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//详情数据
				loading_det: false,
				
				//托盘标预览-图片
				palletMarkImgOpenTime:'',
				palletMarkImgRow:{},

				form: {
					// customer: null,
					container: null,
					direction: null,
					pallet: null,
					location : null,
					customer: null,
					// date: null,
				},

				formRules: {
					direction: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					container: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'blur'
					}],
					pallet: [{
						required: true,
						message: this.$t('5a9aefbc03c778f7'),
						trigger: 'change'
					}],
					// date: [{
					// 	required: true,
					// 	message: this.$t('5a9aefbc03c778f7'),
					// 	trigger: 'change'
					// }],
				},
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {

		},
		//编译挂载前
		mounted() {
			this.initPriterList();
		},
		methods: {
			initData() {
				this.resetForm('form');
			},
			backPageAction() {
				this.$router.go(-1);
			},
			
			//初始化打印机列表
			initPriterList() {
				let printerList = GetPrinterList();
				console.log('printerList', printerList);
				this.printerList = printerList;
			},
			//保存选择的打印机
			changePrinter(v) {
				//保存此次打印机的值
				// savePrtVal(this.printerVal);
				// this.printerVal = v;
				// this.initPagesizesList();
			},
			
			valToUpperCase(){
				this.form.container = this.form.container.toUpperCase()
			},
			valToUpperCase2(){
				this.form.direction = this.form.direction.toUpperCase()
			},
			valToUpperCase3(){
				this.form.location = this.form.location.toUpperCase()
			},
			//初始化打印机列表
			// initPriterList() {
			// 	let printerList = GetPrinterList();
			// 	console.log('printerList', printerList);
			// 	this.printerList = printerList;
			// },
			//保存选择的打印机
			// changePrinter(v) {
				//保存此次打印机的值
				// savePrtVal(this.printerVal);
				// this.printerVal = v;
				// this.initPagesizesList();
			// },
			
			//打开托盘标预览
			openPreview(){
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.palletMarkImgRow = this.form;
						this.palletMarkImgOpenTime = new Date().getTime();
					} else {
						// console.log('error submit!!');
						this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning'
						});
						return false;
					}
				});
			},
			
			//直接打印
			printAction() {
				this.$nextTick(()=>{
					let row = this.form;
					let list = [];
					let pallet = parseInt(row.pallet);
					if(pallet && !isNaN(pallet)){
						for(let i=0,ln=pallet;i<ln;i++){
							let CusIsCn = /[\u4e00-\u9fa5]+/.test(row.customer);
							// let ConIsCn = /[\u4e00-\u9fa5]+/.test(row.container);
							list.push({
								// customer: row.customer,
								CusIsCn: CusIsCn,
								container: row.container,
								// ConIsCn: ConIsCn,
								direction: row.direction,
								location: row.location,
								pallet: (i+1)+"/"+row.pallet,
								customer: row.customer,
								// date: row.date
							});
						}
					} else {
						let CusIsCn = /[\u4e00-\u9fa5]+/.test(row.customer);
						// let ConIsCn = /[\u4e00-\u9fa5]+/.test(row.container);
						list.push({
							// customer: row.customer,
							CusIsCn: CusIsCn,
							container: row.container,
							// ConIsCn: ConIsCn,
							direction: row.direction,
							location: row.location,
							pallet: row.pallet,
							customer: row.customer,
							// date: row.date
						});
					}
					
					// let listData = this.list;
					let data = exportPdfByPalletMark2("#formCard",list, "PalletMarkCode", {
						size: this.option.size
					},'2','2');
					// console.log(data);
					// priterPdfView();
					priterPdfView("palletMark", '',data, this.printerVal,'1');
				});
				
			},
			
			resetForm(formName) {
				console.log(formName);
				this.form = {
					// customer: null,
					container: null,
					direction: null,
					location: null,
					pallet: null,
					customer: null,
					// date: null,
				}
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
					// this.form.remark = ""; //  备注//非必填
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>