import { render, staticRenderFns } from "./dialogPalletMarkImg2.vue?vue&type=template&id=003ca66d&scoped=true&"
import script from "./dialogPalletMarkImg2.vue?vue&type=script&lang=js&"
export * from "./dialogPalletMarkImg2.vue?vue&type=script&lang=js&"
import style0 from "./dialogPalletMarkImg2.vue?vue&type=style&index=0&id=003ca66d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003ca66d",
  null
  
)

export default component.exports